<script setup lang="ts">
const { t } = useT();
</script>

<template>
	<section>
		<div class="simple-steps">
			<AText variant="tamale" as="h3" :modifiers="['uppercase', 'center']" class="title">
				<i18n-t keypath="{key} TO START PLAYING">
					<template #key>
						<span>{{ t("3 SIMPLE STEPS") }}</span>
					</template>
				</i18n-t>
			</AText>
			<div class="content">
				<div class="box">
					<div class="step one">1</div>
					<div class="text">
						<AText variant="tuzla" as="p" :modifiers="['bold', 'uppercase']" class="subtitle">
							{{ t("Sign up") }}
						</AText>
						<AText variant="toledo" as="div" class="description">
							<i18n-t keypath="Register a free account {key} and get 200K Gold Coins!">
								<template #key>
									<br />
								</template>
							</i18n-t>
						</AText>
					</div>
				</div>
				<div class="box">
					<div class="step">2</div>
					<div class="text">
						<AText variant="tuzla" as="p" :modifiers="['bold', 'uppercase']" class="subtitle">
							{{ t("Start playing") }}
						</AText>
						<AText variant="toledo" as="div" class="description">
							<i18n-t keypath="Collect FREE Fortune Coins {key} to feel the game full of rewards!">
								<template #key>
									<br />
								</template>
							</i18n-t>
						</AText>
					</div>
				</div>
				<div class="box">
					<div class="step">3</div>
					<div class="text">
						<AText variant="tuzla" as="p" :modifiers="['bold', 'uppercase']" class="subtitle">
							{{ t("Have fun") }}
						</AText>
						<AText variant="toledo" as="div" class="description">
							<i18n-t keypath="Dive into the Fortune Coin Mode {key} with fast Prize Redemtion!">
								<template #key>
									<br />
								</template>
							</i18n-t>
						</AText>
					</div>
				</div>
			</div>
			<NuxtLink to="/how-it-works/" class="footer">
				<AButton variant="primary" size="xl" animation="pulse">
					<AText variant="turin">{{ t("Read more") }}</AText>
				</AButton>
			</NuxtLink>
		</div>
	</section>
</template>

<style scoped lang="scss">
section {
	padding: gutter(3) gutter(0) gutter(4);

	@include media-breakpoint-down(lg) {
		padding: gutter(1) gutter(2);
	}

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) gutter(2) gutter(5);
	}
}

.simple-steps {
	padding: gutter(7.5) gutter(2) gutter(5.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: gutter(4);
	position: relative;
	border-radius: 12px;
	background: var(--glasgow);

	@include media-breakpoint-down(md) {
		padding: gutter(2) gutter(2) gutter(6.25);
		gap: gutter(2);
		align-items: flex-start;
	}

	@include media-breakpoint-up(md) {
		overflow: hidden;
	}

	.title {
		line-height: 50px;
		position: relative;

		span {
			color: var(--caracas);
		}

		@include media-breakpoint-down(md) {
			width: 100%;
			font-size: 24px;
			line-height: 30px;
			color: var(--caracas);

			span {
				display: block;
				color: var(--cannes);
			}
		}
	}

	.content {
		display: flex;
		justify-content: center;
		gap: gutter(4);

		@include media-breakpoint-down(xl) {
			gap: gutter(2);
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}

	.text {
		max-width: 244px;
		display: flex;
		flex-direction: column;
		gap: gutter(0.5);
		position: relative;
		z-index: 1;

		@include media-breakpoint-down(md) {
			flex: 1;
			max-width: none;
			align-items: flex-start;
			gap: gutter(1);
		}
	}

	.description {
		color: var(--cairo);
		@include media-breakpoint-down(md) {
			text-align: left;
			font-size: 14px;
		}
	}

	.box {
		position: relative;
		display: flex;
		align-items: center;
		gap: gutter(2);
	}

	.footer {
		display: block;
		width: 268px;

		@include media-breakpoint-down(md) {
			position: absolute;
			bottom: -16px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		button {
			width: 100%;
		}
	}

	.step {
		width: 50px;
		display: block;
		font-size: 80px;
		color: var(--comal);
		font-weight: 800;
		line-height: 34.375px;
		z-index: 0;

		@include media-breakpoint-up(lg) {
			width: 90px;
			text-align: center;
			font-size: 100px;
		}
	}

	.primary {
		position: relative;
		overflow: visible;
	}

	&:before {
		@include media-breakpoint-up(md) {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: var(--glasgow);
			border-radius: 16px;
			animation: changeGradient 5s ease-in-out infinite;
		}
	}
}

@keyframes changeGradient {
	0% {
		background-position: 0 0;
	}

	50% {
		transform: scale(2) translateY(20%);
	}

	100% {
		background-position: 0 0;
	}
}
</style>
